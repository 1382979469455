import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SEO from '../components/seo'

export const query = graphql`
query($slug: String!) {
  contentfulBlogPost(slug: { eq: $slug }) {
    title
    publishedDate(fromNow: true)
    body {
      json
    }
  }
}
`
const Blog = ({ data }) => {
  const { contentfulBlogPost: { title, publishedDate, body }} = data
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title['en-US']
        const url = node.data.target.fields.file['en-US']
        return <img alt={alt} src={url} />
      }
    }
  }
  return ( 
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <h2>{publishedDate}</h2>
      {documentToReactComponents(body.json, options)}
    </Layout>
  )
 }

 export default Blog
